@import 'variables';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scrollbar-gutter: stable;
}

body {
  background-color: var(--color-background-hex);
  color: var(--color-foreground-hex);
  font-family: var(--font-graphik);
  max-width: 100vw;
  overflow: initial;
  overflow-x: hidden;
}

img {
  display: inline-block;
}

input,
textarea {
  font-family: var(--font-graphik);
}

a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

hr {
  border: none;
  border-top: 1px solid var(--color-background-hex);
}

b,
strong {
  font-weight: var(--font-weight-bold);
}

.onetrust-pc-dark-filter {
  display: none;
}

.tag_cloud {
  display: inline-flex;
  flex-wrap: wrap;
  gap: spacing(4);
  > * {
    font-size: 14px;
  }
}
