@import 'utils';

:root {
  ---01-normal: 0px 0px 24px 0px #e8e8e8;
  --neutrals-01-white: #fff;

  --interaction-02-default: var(--color-neutral-500);
  --interaction-03-default: var(--color-neutral-500);
  --interaction-04-hover-poll: #e6efffff;
  --color-background-hex: var(--color-white);
  --color-badge: #af931c;
  --color-black: #000;
  --color-brand-gartner-blue: #002856;
  --color-brand-lemon: #ffad1a;

  --color-brand-rose: #dd2161;

  /* START GRAVITY */
  --color-brand-sky: #40b3e1;
  --color-brand-surf: #2bcdbc;
  --color-brand-tangerine: #ed6926;
  --color-cultured-white: #f6f7f9;
  --color-foreground-hex: var(--color-black);
  --section-heading-color: var(--color-black);

  --landing-hero-gradient-color: #002796;

  --color-initeration-tint-01: #f6faff;
  --color-interaction-tint-02: #e6efff;

  --color-neutral-100: #fcfcfc;
  --color-neutral-200: #f6f7f9;
  --color-neutral-300: #eff2f4;
  --color-neutral-400: #e6eaee;
  --color-neutral-500: #97a8b4;
  --color-neutral-600: #5d7383;
  --color-neutral-700: #3e3e3e;
  --color-neutral-800: #212932;
  --color-neutral-900: #1a222b;
  --color-pale-robin-egg-blue: #8de6dc;

  --color-pale-robin-egg-blue-rgba: 141, 230, 220, 1;
  --color-picton-blue: var(--color-brand-sky);
  --color-polar: #f5fbfd;

  --color-poll-result: #ecfbf9ff;
  --button-primary-bg-color: var(--color-utility-interaction-500);
  --color-product-text: #2f3940;

  --border-radius: 4px;
  --star-rating-bg-color: #a6a6a6;
  --star-rating-color: #fdc10d;
  --background-rgb: 255, 255, 255;

  --box-shadow-01-large: 0px 13px 37px rgba(0, 0, 0, 0.3);
  --box-shadow-01-normal: 0px 0px 12px #00285614;
  --box-shadow-01-raised: 0px 3px 24px 0px #c3c3c3;

  --box-shadow-02-large: 0px 0px 20px 0px rgba(0, 0, 0, 0.08); // TO DO: SYNC WITH GRAVITY

  --box-shadow-02-normal: 0px 8px 10.32px rgba(0, 40, 86, 0.08);
  --box-shadow-03-light: 0px 0px 24px rgba(0, 40, 86, 0.08);
  /* END GRAVITY */

  /* BETA GRAVITY */
  --box-shadow-03-normal: 0px 0px 12px #00000066;
  --box-shadow-card: 0px 4px 20px var(--color-neutral-400);
  /* END BETA GRAVITY */

  --box-shadow-medium: 0px 0px 18px rgba(0, 0, 0, 0.3);
  --box-shadow-small: 0px 6px 12px rgba(0, 0, 0, 0.3);
  --box-shadow-xsmall: 0px 1px 2px rgba(0, 0, 0, 0.3);

  --color-utility-error-100: #de0a01;

  --color-utility-error-200: #aa0000;
  --color-utility-error-50: #fce7e6;
  --color-utility-interaction-100: #6ca5ff;
  --color-utility-interaction-200: #478eff;
  --color-utility-interaction-25: #deebff;

  --color-utility-interaction-50: #91bbff;
  --color-utility-interaction-500: #0000a9;

  --color-utility-interaction-600: #0000d3;

  --color-utility-interaction-700: #00007f;

  /* BOX SHADOWS */
  --color-utility-success-100: #00a76d;
  --color-utility-success-200: #007a50;
  --color-utility-success-50: #e6f6f1;
  --color-utility-warning-100: #f5ab23;

  --color-utility-warning-200: #8d5a00;
  --color-utility-warning-50: #fef7e9;
  --font-weight-bold: 700;
  --font-weight-normal: 400;

  --color-white: #fff;

  --max-width: 1100px;
  /* END BOX SHADOWS */
}